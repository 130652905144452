




import { computed } from '@vue/composition-api';

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    value: {
      type: String,
      default: ''
    }
  },
  setup(props, ctx) {
    const inputValue = computed({
      get: () => props.modelValue,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    return { inputValue };
  }
};
